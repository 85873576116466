<template>
  <div class="page">
    <el-form ref="form" :model="form">
      <el-form-item style="margin-bottom: 20px" label="询盘单" prop="day">
        <div style="float: left">客服超过</div>
        <el-input v-model="form.days" style="width: 10%; float: left; margin: 0 10px" size="small" />
        <div style="float: left">天没用回复,自动关闭订单</div>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="结尾附加信息" prop="addon">
        <tinymce v-model="form.addon" placeholder="请输入附加信息"  />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="第一封邮件内容" prop="mail_after_create">
        <tinymce v-model="form.mail_after_create" placeholder="请输入内容"  />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="第一封邮件标题" prop="title_of_mail_after_create">
        <el-input v-model="form.title_of_mail_after_create" placeholder="请输入标题"  />
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="onSubmit">提交</el-button>
  </div>
</template>
<script>
import {apiGetInquiryEmail, apiSetInquiryEmail} from "@/request/api";

export default {
  components: {},
  props: [],
  data() {
    return {
      form: {
        days: '',
        addon: '',
        mail_after_create:'',
        title_of_mail_after_create:'',
      },
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      apiGetInquiryEmail().then(res => {
        if(res.code == 200){
          this.form = res.data
        }
      })
    },
    onSubmit(){
      apiSetInquiryEmail(this.form).then(res => {
        if(res.code == 200){
          this.$message({
            message: '保存成功',
            type: 'success'
          });
        }
      })
    }
  }
}

</script>
<style>
  .el-pagination {
    text-align: center;
    margin-top: 20px;
  }

  .el-form-item {
    margin-bottom: 0;
  }

  .el-dropdown {
    margin-right: 10px;
  }

  .page {
    background-color: #FFF;
    padding: 20px;
    margin: 20px;
  }

  .el-row {
    margin-bottom: 15px;
  }
</style>
